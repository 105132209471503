<template>
  <v-container id="subscribe" fluid tag="section" style="padding-bottom: 150px;">
    <section>
    <v-overlay :value="overlay"></v-overlay>      
    <div id="dialog" class="text-center">
      <v-dialog v-model="showLoginRequiredDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Subscription
          </v-card-title>

          <v-card-text>
            Please <router-link to="/pages/login">login</router-link> or
            <router-link to="/pages/signup">sign up</router-link> first in order to subscribe.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" text @click="showLoginRequiredDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div id="alreadySubscribed">  
        <v-card 
          v-show="currentSubscription != null && currentSubscription !== 'loading'" 
          cols="8" lg="8">
          <v-card-text>
            <v-alert
              border="left"
              color="blue"
              elevation="8"
              icon="mdi-account"
              prominent
              type="success"
            >
              You are currently subscribed at
              {{currentSubscription}}.
              <br/>
              Thank you for being a loyal customer. 
              <br/>
              To create a new report simply click the Create New Report button below to get started.
              <br/>
              Alternatively please visit your account page to alter your subscription or add more credits.  
            </v-alert> 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  class="mb-4"
                    color="primary"
                    to="/pages/search"
                  >
                    Create New Report
            </v-btn>
            <v-btn  class="mb-4"
                    to="/pages/user"
                  >
                    Open My Account
            </v-btn>
          </v-card-actions>
        </v-card>
    </div>
    <div id="subscriptionPlans">
      <v-row v-show="currentSubscription == undefined || currentSubscription == null">
        <v-col cols="12" lg="12">
          <v-card>
            <v-card-title class="text-center justify-center py-6">
              <h1 class="font-weight-bold text-h2">
                PLANS
              </h1>              
            </v-card-title>

            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab v-for="item in tablist" :key="item">
                {{ item }} 
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tablist" :key="item">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <template v-for="(product, index) in products">
                        <template v-for="(price, priceindex) in product.prices">
                          <v-col v-if="((product.role === 'oneoff' || product.role === 'standard' || product.role === 'professional') && tab === 0) 
                                        || ((product.role === 'business' || product.role === 'enterprise') && tab === 1)" :key="price.id" cols="12" lg="4" >
                            <div v-if="debug"> 
                              Tab {{tab}} <br/>
                              Product Index {{index}} <br/>
                              Product ID {{product.id}} <br/>
                              Price Index {{priceindex}} <br/>
                              Price ID {{price.id}} <br/> 
                              Price Interval {{price.interval}} <br/>
                              Product Role {{product.role}} <br/>
                            </div>
                            <v-hover
                              v-slot="{ hover }"
                              open-delay="200"
                            >
                                <base-material-pricing-card
                                  :index="priceindex"
                                  :product="product"
                                  :price="price"
                                  :tabtype="tab"
                                  :btnloader="loader"
                                  :btnloading="loading"
                                  :elevation="hover ? 16 : 2"
                                  :class="{ 'on-hover': hover }"
                                  @do-subscription="handleSubscription"
                                />
                            </v-hover>
                          </v-col>
                        </template>
                      </template>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </div>
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const auth = firebase.auth();
const db = firebase.firestore();
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
const defaultInterval = "month";

export default {
  name: "Subscription",

  data() {
    return {
      loader: null,
      loading: false,
      overlay: false,
      showLoginRequiredDialog: false,
      monthlyPaymentType: true,
      currentSubscription: 'loading',
      tab: 0,
      debug: false,
      tablist: ["For Individuals", "For Business"],
      products: {},
    };
  },

  computed: mapState({
      user: state => state.user.user,
  }),

  created() {
    if (this.$route.query && this.$route.query.showTab) {
      this.tab = Number(this.$route.query.showTab);
    }
  },

  methods: {
    async handleSubscription(product, selectedPrice) {

      if (product.role === 'enterprise') {
        // change to mailto link
        window.open("https://propcheck.com.au/business-premium-enquiries", "_blank")
        return;
      }
      if (!this.user || !auth.currentUser.uid) {
        // no user logged in so send to login page        
        this.showLoginRequiredDialog = true;
        // this.$route.push('/pages/login')
        return
      }
      // Our event handler gets the event, as well as any
      // arguments the child passes to the event
      // const selectedProduct = product; // PRODUCT PASSED IN NOW
      // console.log("SELECTED PRODUCT IS "+JSON.stringify(product));
      // const selectedPrice = selectedProduct.prices[0]; // PRICE PASSED IN NOW
      // console.log("SELECTED PRICE IS "+JSON.stringify(selectedPrice));
      this.overlay = true;
      this.loader = {};
      this.loading = true;
      
      // support one time prices
      // https://github.com/stripe/stripe-firebase-extensions/blob/next/firestore-stripe-subscriptions/CHANGELOG.md#version-0112---2021-04-29
      // add mode: "payment" here
      const isOneTime = selectedPrice.type === "one_time";
      let checkoutInfo = {
        price: selectedPrice.id,
        allow_promotion_codes: true,
        success_url: !isOneTime?window.location.origin + "/#/pages/subscribe":window.location.origin + "/#/pages/search",
        cancel_url: window.location.origin  + "/#/pages/subscribe",
      };

      if (isOneTime) {
        checkoutInfo = {
          ...checkoutInfo,
          mode: "payment",
        };
      }

      const docRef = await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .collection("checkout_sessions")
        .add(checkoutInfo);

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(snap => {
        const { sessionId } = snap.data();
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // stripe setup on the window at start of class
          this.loader = null;
          this.loading = true;
          this.overlay = false;
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },

  firestore() {
    try {
      db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("subscriptions")
          .where("status", "in", ["trialing", "active"])
          .onSnapshot(async (snapshot) => {
            // this.overlay = true;
            if (snapshot.empty) {
              // Show products
              // document.querySelector("#subscribe").style.display = "block";
              this.currentSubscription = null;
              // this.overlay = false;
              return;
            }
            // document.querySelector("#subscribe").style.display = "none";
            // document.querySelector("#my-subscription").style.display = "block";
            // In this implementation we only expect one Subscription to exist
            const subscription = snapshot.docs[0].data();
            const productData = (await subscription.product.get()).data();
            const priceData = (await subscription.price.get()).data();
            this.currentSubscription = `${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: priceData.currency
            }).format((priceData.unit_amount / 100).toFixed(2))} per ${
              priceData.interval
            } on our ${ productData.name } plan`;
            // this.overlay = false;
          });
    } catch (Exception){ 
      // failed to retrieve user subscription information
      this.currentSubscription = null;
    }

    db.collection("products")
      .where("active", "==", true)
      .onSnapshot(
        querySnapshot => {
          let documents = querySnapshot.docs.map(productDoc => {
            const item = productDoc.data();
            item.id = productDoc.id;
            item.prices = [];
            // console.debug(`got product from db ${JSON.stringify(item)}`)
            productDoc.ref
              .collection("prices")
              // .where("active", "==", true)
              .orderBy("unit_amount")
              .onSnapshot(snapshot => {
                snapshot.docs.forEach(priceDoc => {
                  const priceId = priceDoc.id;
                  const priceData = priceDoc.data();
                  if (priceData.active) {
                    // filter out items we dont want to display 
                    if (item.role === "oneoff" // single report 
                      || priceData.interval === defaultInterval // all default/monthly report types
                      || (priceData.interval === "year" && item.role === "business")) {
                        // and yearly business pricing
                      item.prices.push({
                        id: priceId,
                        ...priceData,
                      });
                    }
                  }
                  // console.debug(`found prices ${JSON.stringify(priceData)}`);
                }); // foreach
              }); // onSnapshot
            return item;
          });

          // console.debug(`got products from db ${JSON.stringify(documents)}`)
          if (this.$route.query && this.$route.query.showRoles) {
            documents = documents.filter(document => {
              return document.role === this.$route.query.showRoles;
            }); 
          }
          // sort the prices by their displayOrder property in stripe
          documents.sort((a, b) => {
            // cant use below as price not populated yet here
            // return a.prices[0].unit_amount - b.prices[0].unit_amount;
            return a.metadata.displayOrder - b.metadata.displayOrder;
          });
          const dummyEnterprisePrice = {
            active: true,
            description: "A Business Premium Account which can be have seats for multiple users in your organisation.",
            name: "Business Premium",
            role: "enterprise",
            metadata: {
              displayOrder: "99999999",
              firebaseRole: "enterprise"
            },
            prices: [
              {
                active: true,
                currency: "AUD",
                unit_amount: "9999999", 
                interval: null,
                intervalCount: null,
                type: "enterprise", 
              },
            ],
          }
          documents.push(dummyEnterprisePrice)

          this.products = documents;
        },
        error => {
          // eslint-disable-next-line no-console
          console.error("Error fetching documents: ", error);
        }
      );
  },
};
</script>
